import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const App: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 font-sans">
      <header className="flex items-start mb-8">
        <img src="images/profile_picture.jpg" alt="Profile Picture" className="w-48 h-48 mr-6 object-cover" />
        <div>
          <h1 className="text-3xl font-bold mb-2">Lorenzo Lucena Maguire</h1>
          <p className="text-xl mb-1">Incoming Software Engineer @ Google</p>
          <p className="mb-1">Email: <a href="mailto:lorenzo.lucenamaguire@gmail.com" className="text-blue-600 hover:underline">lorenzo.lucenamaguire@gmail.com</a></p>
          <p className="mb-1">Buenos Aires, Argentina</p>

          <a
            href="https://www.linkedin.com/in/lorenzo-lucena-maguire"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-800"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
        </div>
      </header>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">About me</h2>
        <p className="mb-4">
          I'm an incoming Software Engineer @ Google. I graduated with Bachelor's and Master's degrees in Computer Science from the University of Pennsylvania.
        </p>
        <p className="mb-4">

        </p>
        <p className="mb-4">
          Interned at Amazon (Amazon Style, 2022) and Google (Fitbit, 2023).
        </p>


      </section>
      {/* <hr></hr> */}
      <br></br>
      {/* <section>
        <h2 className="text-2xl font-bold mb-4">Personal Projects</h2>
        <ul className="space-y-4">
          <h3 className="text-xl font-semibold">Coming soon: Refactoring and deploying projects</h3>
        </ul>
        <ul className="space-y-4">
          <li>
            <h3 className="text-xl font-semibold">Project Name 1</h3>
            <p className="text-gray-700">Brief description of the project, its purpose, and the technologies used.</p>
          </li>
          <li>
            <h3 className="text-xl font-semibold">Project Name 2</h3>
            <p className="text-gray-700">Explanation of another project, highlighting your role and any notable outcomes.</p>
          </li>
          <li>
            <h3 className="text-xl font-semibold">Project Name 3</h3>
            <p className="text-gray-700">Details about a third project, possibly including links or screenshots if applicable.</p>
          </li>
        </ul>
      </section> */}
    </div>
  );
};
export default App;